<template>
  <v-container fluid class="background pa-0">
    <v-img
      style="align-items: center"
      :height="$vuetify.breakpoint.smAndUp ? '840' : '400'"
      :src="
        $vuetify.breakpoint.smAndUp
          ? require('@/assets/images/ServicePage/TopMain.jpg')
          : require('@/assets/images/ServicePage/TopMain_Mobile.jpg')
      "
    >
      <v-container
        fluid
        class="d-flex imgBackground ma-auto align-center justify-center"
        :style="$vuetify.breakpoint.smAndUp ? 'height: 840px' : 'height: 400px'"
      >
        <v-card
          class="d-flex flex-column transparent ma-auto pt-6"
          style=""
          dark
          flat
        >
          <v-card-subtitle
            class="justify-center mx-auto mb-0"
            :class="
              $vuetify.breakpoint.smAndUp ? 'display-2' : 'headline mt-10 pb-0'
            "
          >
            <span>상상을 현실로 만드는</span>
          </v-card-subtitle>
          <v-card-title
            class="justify-center display-2 white--text mt-0 pb-10"
            :class="$vuetify.breakpoint.smAndUp ? 'display-2' : 'headline'"
          >
            <span class="font-weight-bold">3D 프린팅 제조 서비스</span>
          </v-card-title>
          <v-card-text
            class="d-flex white--text"
            :class="
              $vuetify.breakpoint.smAndUp ? 'mb-5 title' : 'mb-0 subtitle'
            "
          >
            <span class="mx-auto font-weight-light text-center">
              메이드올의 모든 고객님들에게
              <br v-if="!$vuetify.breakpoint.mdAndUp" />
              최고의 제조 서비스를 제공할 것을 보장합니다
            </span>
          </v-card-text>
          <v-spacer />
          <v-card-actions class="d-flex mx-auto my-3">
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? '220px' : '120px'"
              :height="$vuetify.breakpoint.mdAndUp ? '72px' : '48px'"
              color="primary"
              class="font-weight-bold mr-3"
              :class="
                $vuetify.breakpoint.mdAndUp ? 'mb-5 title' : 'mb-0 subtitle'
              "
              style="border-radius: 8px"
              @click="navigateTo('Order')"
            >
              제조 의뢰하기
            </v-btn>
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? '220px' : '120px'"
              :height="$vuetify.breakpoint.mdAndUp ? '72px' : '48px'"
              class="font-weight-medium"
              :class="
                $vuetify.breakpoint.mdAndUp ? 'mb-5 title' : 'mb-0 subtitle'
              "
              style="
                border-radius: 8px;
                border: 1px solid white;
                background: transparent;
              "
              @click="
                open(
                  'https://docs.madeall3d.com/9b5a8d24-1bf2-4471-9d67-64ad18fa4ad5',
                )
              "
            >
              의뢰 가이드
            </v-btn>
          </v-card-actions>
          <v-card-text class="d-flex mt-10 pt-10" v-if="$vuetify.breakpoint.smAndUp">
            <v-row style="max-width: 1200px" class="mx-auto">
              <v-col class="d-flex" style="min-width: 260px" cols="6" lg="3">
                <div class="mx-auto d-flex">
                  <span class="display-1 white--text font-weight-bold my-auto">
                    1:1
                  </span>
                  <span class="pl-5 subtitle-1">
                    전문가 컨설팅으로 <br />
                    빠르고 정확하게
                  </span>
                </div>
              </v-col>
              <v-col class="d-flex" style="min-width: 260px" cols="6" lg="3">
                <div class="mx-auto d-flex">
                  <span class="display-1 white--text font-weight-bold my-auto">
                    직접
                  </span>
                  <span class="pl-5 subtitle-1">
                    연구개발한 고품질 <br />
                    FDM 3D프린팅 기술
                  </span>
                </div>
              </v-col>
              <v-col class="d-flex" style="min-width: 260px" cols="6" lg="3">
                <div class="mx-auto d-flex">
                  <span class="display-1 white--text font-weight-bold my-auto">
                    1위
                  </span>
                  <span class="pl-5 subtitle-1">
                    네이버 쇼핑 기준 <br />
                    최다 판매량 및 리뷰 수
                  </span>
                </div>
              </v-col>
              <v-col class="d-flex" style="min-width: 260px" cols="6" lg="3">
                <div class="mx-auto d-flex">
                  <span class="display-1 white--text font-weight-bold my-auto">
                    4.9
                  </span>
                  <span class="pl-5 subtitle-1">
                    압도적인 1위 네이버 <br />
                    고객 리뷰 만족도
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </v-img>
  </v-container>
</template>
<style scoped>
.imgBackground {
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
}
.span-button:hover {
  border-bottom: 1px solid white;
}
</style>
<script>
/* eslint-disable vue/no-unused-components */
export default {
  data() {
    return {};
  },
  mounted() {},

  methods: {
    downloadService() {
      window.open(
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2F%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B3%E1%84%8B%E1%85%A9%E1%86%AF%20%E1%84%89%E1%85%A5%E1%84%87%E1%85%B5%E1%84%89%E1%85%B3%20%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf?alt=media&token=bd84e308-49e0-49ad-8bef-12272070e9c2",
      );
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView();
    },
    navigateTo(route) {
      if (this.$router.currentRoute.name !== route) {
        this.$router.push({ name: route }).then(() => window.scrollTo(0, 0));
      }
    },
    open(link) {
      window.open(link);
    },
  },
};
</script>

<style></style>
