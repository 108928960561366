<template>
  <v-container fluid :class="$vuetify.breakpoint.mdAndUp ? '' : 'pt-10 pb-10'">
    <v-row style="max-width: 1200px" class="mx-auto">
      <v-col cols="12" align-self="center">
        <v-card class="transparent text-center" style="" flat>
          <v-card-title
            :class="$vuetify.breakpoint.smAndUp ? 'display-1' : 'title pb-0'"
          >
            <span class="mx-auto text_title">더 많은 작품이 궁금하신가요?</span>
          </v-card-title>
          <v-card-text
            :class="$vuetify.breakpoint.smAndUp ? 'title' : 'caption'"
            class="d-flex mt-2 mb-10"
          >
            <span
              class="mx-auto grey--text text--darken-2"
              :class="
                $vuetify.breakpoint.smAndUp
                  ? 'text_subtitle'
                  : 'subtitle-1 pb-0'
              "
            >
              작품을 선택하시면 자세한 내용을 볼 수 있어요
            </span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-row v-if="fetchLoading">
        <v-col v-for="index in 4" :key="index" cols="6" md="3">
          <v-skeleton-loader class="rounded-lg" type="card" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col v-for="item in items" :key="item.id" cols="6" md="3">
          <portfolio-card-dialog :content="item" />
        </v-col>
      </v-row>

      <v-col cols="12" class="d-flex">
        <v-btn
          color="primary rounded-lg body-2 font-weight-bold"
          :large="$vuetify.breakpoint.mdAndUp"
          depressed
          @click="$router.push({ name: 'Portfolio' })"
          class="mx-auto mt-8"
          small
        >
          <feather size="18" type="plus" />
          작품 더보기
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PortfolioCardDialog from "@/components/Dialog/PortfolioCardDialog.vue";
/* eslint-disable vue/no-unused-components */
export default {
  components: {
    PortfolioCardDialog,
  },
  data() {
    return {
      fetchLoading: false,
      options: {
        sortBy: ["createdAt"],
        sortDesc: [true],
      },
      items: [],
    };
  },
  mounted() {
    this.getPreview();
  },
  methods: {
    getPreview() {
      this.fetchLoading = true;
      this.$axios
        .get("portfolios/preview", {
          params: {
            limit: 12,
            order: this.options.sortBy[0],
            sort: this.options.sortDesc[0] ? "desc" : "asc",
          },
        })
        .then(({ data }) => {
          this.items = data.items;
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        })
        .finally(() => {
          this.fetchLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.text_title {
  color: #222;
  font-weight: 700;
  line-height: 130%; /* 40.8px */
}
.text_subtitle {
  color: #222;
  font-weight: 400;
  line-height: 130%;
}
</style>
