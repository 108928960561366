<template>
  <v-container fluid>
    <v-card class="transparent" flat>
      <v-card-title
        class="text_title"
        :class="$vuetify.breakpoint.smAndUp ? 'display-1 ' : 'title'"
      >
        <span class="mx-auto">누구나 쉽게 이용할 수 있는</span>
      </v-card-title>
      <v-card-subtitle
        class="d-flex text_subtitle"
        :class="$vuetify.breakpoint.smAndUp ? 'display-1 ' : 'title pb-0'"
      >
        <span class="mx-auto">3D프린팅 제조 서비스</span>
      </v-card-subtitle>
      <v-card-text
        :class="$vuetify.breakpoint.smAndUp ? 'title' : 'subtitle-1'"
        class="d-flex mt-4"
      >
        <span
          class="mx-auto text_subtitle grey--text text--darken-2 text-center"
        >
          메이드올은 개인부터 기업까지 모두가 이용하고 있습니다!
        </span>
      </v-card-text>
      <v-card-text class="pt-12 pb-2 d-flex">
        <span class="ml-auto caption grey--text text--darken-2">
          *실제 네이버 리뷰 사진입니다.
        </span>
      </v-card-text>
    </v-card>
    <div class="image-carousel">
      <div class="image-container image-container1">
        <div v-for="n in 30" :key="n" class="image-item mr-5">
          <v-img
            :src="require(`@/assets/images/ServicePage/Image/${n % 14}.png`)"
            alt="Image Card"
            class="image"
            :aspect-ratio="4 / 3"
          ></v-img>
        </div>
      </div>
    </div>

    <!-- 하단 div -->
    <div class="image-carousel mt-10">
      <div class="image-container image-container2">
        <div v-for="n in 30" :key="n" class="image-logo-item mx-10">
          <v-img
            :src="require(`@/assets/images/ServicePage/Logo/${n % 14}.png`)"
            alt="Image Card"
            class="image-logo justify-center"
            contain
          ></v-img>
        </div>
      </div>
    </div>
    <div style="height: 144px" />
    <v-card class="transparent" flat id="howto">
      <v-card-title
        :class="$vuetify.breakpoint.smAndUp ? 'display-1' : 'title pb-0'"
      >
        <span class="ml-auto text_title">제조가 처음</span>
        <span class="mr-auto text_subtitle">이신가요? 걱정 마세요!</span>
      </v-card-title>
      <v-card-text
        :class="$vuetify.breakpoint.smAndUp ? 'title' : 'subtitle-1'"
        class="d-flex mt-2"
      >
        <span
          class="mx-auto text_subtitle grey--text text--darken-2 text-center"
        >
          파일 검토부터 AS까지 전문가와 체계적인 시스템이 고객님과 함께 합니다
        </span>
      </v-card-text>
    </v-card>
    <div class="mt-12 d-flex">
      <div v-if="$vuetify.breakpoint.mdAndUp" class="mx-auto d-flex">
        <v-card
          v-for="(n, i) in stage"
          :key="n.key"
          max-width="280px"
          height="390px"
          color="#F3F3F3"
          flat
          style="margin-left: 10px; margin-right: 10px"
          class="d-flex"
          rounded="lg"
        >
          <div class="my-auto">
            <v-img
              max-height="110px"
              contain
              :src="require(`@/assets/images/ServicePage/Service/${i}.svg`)"
            />
            <v-card-title class="mt-10">
              <span class="mx-auto"> {{ n.title }} </span>
            </v-card-title>
            <v-card-text class="text-center title font-weight-light">
              {{ n.text }}
            </v-card-text>
          </div>
        </v-card>
      </div>

      <v-row v-else class="d-flex">
        <v-col
          v-for="(n, i) in stage"
          :key="n.key"
          cols="6"
          sm="6"
          :class="$vuetify.breakpoint.smAndUp || 'pa-1'"
        >
          <v-card
            color="#F3F3F3"
            flat
            class=""
            :class="$vuetify.breakpoint.smAndUp ? 'pa-4 ma-2' : 'pa-1 ma-0'"
            rounded="lg"
            min-height="280px"
          >
            <v-card-title class="pt-5 px-0">
              <v-img
                max-height="110px"
                contain
                :src="require(`@/assets/images/ServicePage/Service/${i}.svg`)"
              />
              <span class="mx-auto"> {{ n.title }} </span>
            </v-card-title>
            <v-card-text
              class="text-center font-weight-light"
              :class="$vuetify.breakpoint.smAndUp ? 'title' : 'subtitle-1'"
            >
              {{ n.text }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div style="height: 100px" />
  </v-container>
</template>

<style scoped>
.text_title {
  color: #222;
  font-weight: 700;
  line-height: 130%; /* 40.8px */
  letter-spacing: -2px;
}
.text_subtitle {
  color: #222;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -1px;
}
.image-carousel {
  overflow: hidden;
  position: relative;
}
.image-container {
  display: flex;
  white-space: nowrap;
}
.image-item {
  flex: 0 0 auto;
  width: 240px;
  border-radius: 8px;
  margin-right: 20px;
  overflow: hidden;
}
.image-logo-item {
  flex: 0 0 auto;
  max-height: 64px;
  max-width: 200px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.image-logo {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.image-container1 {
  animation: scrollLeft 40s linear infinite;
}
.image-container2 {
  animation: scrollRight 40s linear infinite;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-220%);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
</style>
<script>
/* eslint-disable vue/no-unused-components */
export default {
  data() {
    return {
      stage: [
        {
          title: "1. 주문서 접수",
          text: "24시간 언제든 온라인으로 주문서를 접수해요!",
        },
        {
          title: "2. 파일 분석 및 견적",
          text: "1대1 전문가 상담을 통해 파일 검토와 견적을 받아봐요!",
        },
        {
          title: "3. 제조 및 검수",
          text: "최고의 효율로 최상의 품질을 싸고 빠르게 공급합니다!",
        },
        {
          title: "4. 검수 및 배송",
          text: "전문가가 직접 검수한 제품을 안전하게 배송해요!",
        },
      ],
    };
  },
};
</script>
