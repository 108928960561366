<template>
  <v-container fluid class="pa-0" style="margin-top: -70px">
    <service-landing-section />
    <div class="d-flex container">
      <v-row style="max-width: 720px" class="mx-auto">
        <v-col class="d-flex pa-0" style="height: 56px">
          <span class="btn" @click="scrollTo('info')"> 서비스 소개 </span>
        </v-col>
        <v-col class="d-flex pa-0">
          <span class="btn" @click="scrollTo('howto')"> 제조 의뢰 방법 </span>
        </v-col>
        <v-col class="d-flex pa-0">
          <span class="btn" @click="scrollTo('portfolio')"> 작업 사례 </span>
        </v-col>
      </v-row>
    </div>
    <v-divider />
    <service-service-section
      :style="
        $vuetify.breakpoint.mdAndUp
          ? 'padding-top: 96px; padding-bottom: 96px'
          : 'padding-top: 20px; padding-bottom: 20px'
      "
      id="info"
    />
    <!-- <service-process-section
      :style="
        $vuetify.breakpoint.mdAndUp
          ? 'padding-top: 96px; padding-bottom: 96px'
          : 'padding-top: 20px; padding-bottom: 20px'
      "
      id="howto"
    /> -->
    <service-portfolio-section
      :style="
        $vuetify.breakpoint.mdAndUp
          ? 'padding-top: 96px; padding-bottom: 96px'
          : 'padding-top: 20px; padding-bottom: 20px'
      "
      id="portfolio"
    />
    <!-- <service-cost-section style="padding-top: 96px; padding-bottom: 96px" />
    <service-FAQ-section style="padding-top: 96px; padding-bottom: 96px" />
    <service-calcualator-section /> -->
  </v-container>
</template>

<style scoped>
.btn {
  margin: auto;
  cursor: pointer;
}
.btn:hover {
  color: var(--v-primary-base); /* Vuetify의 주 테마 색상 변수 */
  font-weight: 600;
}
</style>

<script>
import setMeta from "@/utils/setMeta";
import ServiceLandingSection from "./Components/ServiceLandingSection.vue";
import ServiceServiceSection from "./Components/ServiceServiceSection.vue";
import ServicePortfolioSection from "./Components/ServicePortfolioSection.vue";
// import ServiceProcessSection from "./Components/ServiceProcessSection.vue";
// import ServiceCostSection from "./Components/Cost/ServiceCostSection.vue";
// import ServiceFAQSection from "./Components/ServiceFAQSection.vue";
// import ServiceCalcualatorSection from "./Components/Calculator/ServiceCalcualatorSection.vue";

export default {
  name: "Service",

  components: {
    ServiceLandingSection,
    ServiceServiceSection,
    ServicePortfolioSection,
    // ServiceProcessSection,
    // ServiceCostSection,
    // ServiceFAQSection,
    // ServiceCalcualatorSection,
  },

  beforeCreate() {
    setMeta({
      title: "시제품 제조",
      description: "메이드올의 고품격 3D프린팅 서비스를 만나보세요.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Fservice.png?alt=media&token=29d98da5-7eee-4bc2-be6c-8d1856a75b44",
    });
  },
  methods: {
    scrollTo(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
